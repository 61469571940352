import { ValidatedDevice } from "@common/context/DevicesContext";
import { ConfirmModalRef } from "@containers/Consignments/components/ConfirmModal";
import { MutableRefObject } from "react";

export const OPTIONS = {
    EDIT_NLISID: "NLISID",
    EDIT_RFID: "RFID",
    DELETE: "Delete"
};
const GetDeviceContextMenu = (validatedDevice: ValidatedDevice, setOption: React.Dispatch<React.SetStateAction<string>>, deleteModalRef: MutableRefObject<ConfirmModalRef | undefined>, setUpdatedDeviceId: React.Dispatch<React.SetStateAction<string[]>>) => {
    return [
        validatedDevice?.nLISID ? {
            id: 1,
            title: 'Edit NLISID',
            subtitle: '',
            onClick: () => {
                setOption(OPTIONS.EDIT_NLISID);
                setUpdatedDeviceId([validatedDevice?.nLISID]);
            },
        }
            : null,
        validatedDevice?.rFID ? {
            id: 2,
            title: 'Edit RFID',
            subtitle: '',
            onClick: () => {
                setOption(OPTIONS.EDIT_RFID);
                setUpdatedDeviceId([validatedDevice?.rFID]);
            },
        }
            : null,
        {
            id: 3,
            title: 'Delete',
            className: 'text-error',
            onClick: () => {
                setOption(OPTIONS.DELETE);
                const { current } = deleteModalRef;

                if (current) {
                    current.show();
                }
            },
        }
    ].coalesce();
};

export default GetDeviceContextMenu;
import EmptyList from '@components/Listing/EmptyList';
import Loader from '@components/Loader';
import MLALogger from '@utils/logger';
import classnames from 'classnames';
import React, { useLayoutEffect, useRef } from 'react';

export interface DropdownOption {
    id: any;
    title?: string;
    subtitle?: string;
    tertiaryTitle?: string;
    component?: React.ReactNode;
    className?: string;
    heading?: string;
    emptyText?: string;
    loading?: boolean;
    onClick?: () => void;
}

interface Props {
    value?: any;
    options: DropdownOption[];
    selectedIndex?: number;
    fixedWidth?: string;
    className?: string;
    heading?: string;
    emptyText?: string;
    loading?: boolean;
    error?: boolean;
    errorSlot?: React.ReactNode;
    onOptionClick?: (item: DropdownOption) => void;
}

const DropdownOptions: React.FunctionComponent<Props> = ({ value, options, selectedIndex, className, fixedWidth, heading, loading, emptyText, error, errorSlot, onOptionClick }) => {
    MLALogger.Log(['DropdownOptions'], { value, options, selectedIndex });

    const rootNode = useRef<HTMLDivElement>(null);

    // Lets scroll to selectedIndex on every change
    useLayoutEffect(() => {
        const el = document.getElementsByClassName('Dropdown--Item active');
        if (el) {
            // Get the first element within the active item e.g. the title
            const first = el.item(0);
            if (first && rootNode.current) {
                // rootNode.current.scrollTo(0, first.getBoundingClientRect().top - rootNode.current.getBoundingClientRect().top);
                // IE11 fix
                const firstTop = (first as HTMLElement).offsetTop;
                rootNode.current.scrollTop = firstTop - (heading !== undefined ? 45 : 0); // Add header
            }
        }
    }, [selectedIndex, heading]);

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .Dropdown {
                    position: relative;
                    overflow-y: scroll;
                    max-height: grid(85);
                    max-width: $form-input-maxwidth;
                    background-color: $color-white;
                    border-radius: 0 0 $border-radius $border-radius;
                    border: 1px solid $color-secondary;
                    margin-top: -2px;
                    @include box-shadow();
                    z-index: 10;

                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 90%);
                    }

                    /* Hide Scrollbar */
                    scrollbar-width: none; /* Firefox */
                    -ms-overflow-style: none; /* IE 10+ */
                    &::-webkit-scrollbar {
                        /* Webkit */
                        width: 0;
                        height: 0;
                    }

                    &--Item {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        cursor: pointer;
                        border-bottom: 1px solid $color-border;

                        &.standard {
                            min-height: grid(12);
                            padding: grid(3);
                            &:hover {
                                background-color: $color-grey96;

                                @media (prefers-color-scheme: dark) {
                                    background: darken($color-grey96, 80%);
                                }
                            }
                        }
                        &.active {
                            background-color: $color-grey90;
                            &:hover {
                                background-color: $color-grey90;

                                @media (prefers-color-scheme: dark) {
                                    background: darken($color-grey90, 80%);
                                }
                            }

                            @media (prefers-color-scheme: dark) {
                                background: darken($color-grey90, 80%);
                            }
                        }

                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                    &--Subitle {
                        margin-top: grid(1);
                    }
                    &--Header {
                        background-color: $color-white;
                        padding: grid(3);
                        border-bottom: 1px solid $color-border;

                        @include text-midbold;
                        @include text-small;

                        @media (prefers-color-scheme: dark) {
                            background: darken($color-white, 80%);
                        }
                    }

                    &--Footer {
                        z-index: 11;
                        .Dropdown--Item {
                            background-color: $color-white;
                            min-height: grid(10);
                            @include text-midbold;

                            @media (prefers-color-scheme: dark) {
                                background: darken($color-white, 80%);
                            }
                        }
                    }
                }
            `}</style>

            <div ref={rootNode} style={{ width: fixedWidth ? fixedWidth : 'initial' }} className={classnames('Dropdown', { fixedWidth }, className)}>
                {heading && <div className="Dropdown--Header">{heading}</div>}
                {!loading && options.length === 0 && !error && (
                    <div className="Dropdown--Empty">
                        <EmptyList emptyText={emptyText} />
                    </div>
                )}
                {error ? errorSlot || <EmptyList emptyText={'Failed'} /> : loading && <Loader isLoading={true} error={false} retry={() => null} timedOut={false} pastDelay={false} />}
                {!loading &&
                    options.map((item, i) => {
                        const idx = item.id === 'MANUAL' ? -1 : i;
                        return (
                            <div
                                className={classnames('Dropdown--Item', item.className, { selected: value === item.id, standard: !item.component, active: i === selectedIndex })}
                                key={item.id}
                                data-idx={idx}
                                onClick={() => onOptionClick?.(item)}
                            >
                                {item.component ? (
                                    item.component
                                ) : (
                                    <>
                                        {item.title && (
                                            <div data-idx={idx} className="Dropdown--Title small">
                                                {item.title}
                                            </div>
                                        )}
                                        {item.subtitle && (
                                            <div data-idx={idx} className="Dropdown--Subitle helper muted">
                                                {item.subtitle}
                                            </div>
                                        )}
                                        {item.tertiaryTitle && (
                                            <div data-idx={idx} className="Dropdown--Subitle helper muted">
                                                {item.tertiaryTitle}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default DropdownOptions;

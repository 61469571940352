import * as React from 'react';

const DeviceTransferReadyIcon = ({color = "#1C4E9C", ...props }) => {
    return (
        <svg
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m19.41 9.58-9-9C10.04.21 9.53 0 9 0H2a2 2 0 0 0-2 2v7c0 .53.21 1.04.59 1.41l.41.4c.9-.54 1.94-.81 3-.81a6 6 0 0 1 6 6c0 1.06-.28 2.09-.82 3l.4.4c.37.38.89.6 1.42.6s1.04-.21 1.41-.59l7-7c.38-.37.59-.88.59-1.41s-.21-1.04-.59-1.42M3.5 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3M8 17H5v3H3v-3H0v-2h3v-3h2v3h3z"
                fill={color}
            />
        </svg>
    );
};

export default DeviceTransferReadyIcon;


import { DateIcon, DeviceTransferFailedIcon, InfoIcon, WarningIcon } from '@assets/icons';
import { useDevicesContext, ValidatedDevice } from '@common/context/DevicesContext';
import { MovementActions, useMovementContext } from '@common/context/MovementContext';
import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import Tooltip from '@components/Form/Tooltip';
import HoverableIcon from '@components/HoverableIcon';
import Loader from '@components/Loader';
import Modal from '@components/Modal';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import ConfirmModal, { ConfirmModalRef } from '@containers/Consignments/components/ConfirmModal';
import { containsDeprecatedForms } from '@containers/Consignments/ConsignmentHelper';
import { cleanConsignmentPIC } from '@containers/Consignments/mutations/createOrSaveConsignment';
import { ValidatedDeviceListQuery as DeviceGqlQuery } from '@containers/Consignments/queries/__generated__/ValidatedDeviceListQuery.graphql';
import { ConsignmentEditQuery } from '@containers/Consignments/queries/ConsignmentEdit';
import { ValidatedDeviceListQuery } from '@containers/Consignments/queries/ValidatedDeviceListQuery';
import ValidationCard from '@containers/Consignments/ValidationCard';
import useDeviceManager from '@effects/useDeviceManager';
import useMovement from '@effects/useMovement';
import { useWindowWidth } from '@effects/useWindowWidth';
import { UNKNOWN_PIC } from '@utils/constants';
import { speciesEnumToName } from '@utils/enum-transformers';
import { ConsignmentSpecies, DeviceValidationStatus, ValidationApiAction } from '@utils/enums';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery } from 'relay-hooks';

import { AddOrUploadNlisDevices } from './Components/AddManuallyOrUpload/AddOrUploadNlisDevices';
import DeviceList from './Components/DeviceList';
import DevicesMovementToFromSection from './Components/DevicesMovementToFromSection';

const DeviceMovementInfo: React.FC<{ consignment: ConsignmentDetailQueryResponse['consignment'] }> = ({
    consignment,
}) => {
    const {
        errorMessage,
        clearFiles,
        validateAndAddDevices,
        deviceIds,
        validatedDevices,
        revalidate,
        clearValidatedDevices,
    } = useDeviceManager();
    const { movementActions } = useMovementContext();
    const [actions, setActions] = useState<MovementActions>();
    const validationFailedRef = useRef<ConfirmModalRef>();
    const replaceAllRef = useRef<ConfirmModalRef>();
    const [showDevicesMovementToFrom, setShowDevicesMovementToFrom] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showDevicesList, setShowDevicesList] = useState(true);
    const [canValidateDevices, setCanValidateDevices] = useState(false);

    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();
    const width = useWindowWidth();
    const onTabSwitch = (index: number) => {
        setActiveTab(index);
        clearFiles();
    };

    // headers
    // create nlis movement
    // add nlis device
    // preview
    // nlis devices added
    // couple of more
    const DeviceMovementInfoHeader = () => {
        const [headingText, setHeadingText] = useState('');
        const [subHeadingText, setSubHeadingText] = useState('');

        useEffect(() => {
            const getHeadingText = () => {
                let text = '';
                if (
                    validatedDevices.totalDevices > 0 &&
                    (actions?.canAddOrEditDevicesForProducer || (!actions?.isReceiver && actions?.canViewMovement))
                ) {
                    text = 'NLIS Devices Added';
                } else if (validatedDevices.totalDevices > 0 && actions?.canPreviewMovement) {
                    text = 'Preview Upcoming Livestock Transfer';
                } else if (!showDevicesMovementToFrom) {
                    text = 'Add NLIS Devices';
                } else {
                    text = 'Create NLIS Movement';
                }

                return text;
            };

            const getSubHeadingText = () => {
                let text = '';
                if (validatedDevices.value.size > 0 && actions?.canPreviewMovement) {
                    text = 'Review Transfer Details';
                } else if (validatedDevices.value.size > 0 && actions?.canPerformMovement) {
                    text = 'Add Details';
                }
                return text;
            };

            setHeadingText(getHeadingText());
            setSubHeadingText(getSubHeadingText());
        }, [headingText]);

        return (
            <>
                <style jsx>
                    {`
                        @import 'vars';
                        @import 'utils';
                        @import 'mixins';

                        h1,
                        h3 {
                            margin: grid(10) 0;
                            color: $color-secondary;
                            margin: 10px 0px;
                            @media (max-width: $sm-min) {
                                margin: grid(6) 0;
                            }
                        }
                    `}
                </style>
                {headingText && (
                    <div>
                        <h1 data-cy="welcome-heading">{headingText}</h1>
                        {showDevicesMovementToFrom && <h3>{subHeadingText}</h3>}
                    </div>
                )}
            </>
        );
    };

    const LivestockDetails = () => (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .summary-panel {
                    flex: 2 0 65%;

                    .date-info {
                        gap: 10px;
                    }

                    .to-details {
                        border-left: 1px solid $grey-border;
                        position: relative;
                    }
                }

                .box {
                    box-sizing: border-box;
                    background-color: $color-white;
                    border: 1px solid $grey-border;
                    border-radius: 4px;
                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }

                .icon-info {
                    color: $info-icon;
                }

                .bold {
                    font-weight: 600;
                }

                .grey-text {
                    color: $color-subtitle;
                    cursor: inherit;
                }
            `}</style>
            <div className="summary-panel box m-t-32 m-b-16">
                <div
                    className={`panel-heading ${
                        width > 740 ? 'flex-row flex-between' : 'flex-start-column'
                    } p-v-16 p-h-16`}
                >
                    <div className="livestock-details-container">
                        <h2>Livestock Details</h2>
                        <div className="flex-center-row m-v-12">
                            <p>Species: {speciesEnumToName(consignment?.species as ConsignmentSpecies)};</p>
                            <p className="flex-center-row p-h-8">
                                Total NLIS devices
                                <div className="icon-info">
                                    <Tooltip
                                        placement="bottom"
                                        title={`What does "Total NLIS devices" mean?`}
                                        text={`"Total NLIS devices" refers to all identifiers used for livestock description in the consignment, including NLIS IDs, RFIDs (ear tags), and rumen devices (cattle only). Ensure consistency between livestock descriptions and your added/upload NLIS devices wherever possible.`}
                                        children={
                                            <InfoIcon
                                                className={''}
                                                style={{ width: 18 }}
                                            />
                                        }
                                    />
                                </div>
                                : {validatedDevices.value.size};
                            </p>
                            <p className="bold">Total Headcount: {consignment?.heads}</p>
                        </div>
                    </div>
                    <div className="date-info flex-center-row ">
                        <DateIcon />
                        <label
                            htmlFor=""
                            className="info-label grey-text"
                        >
                            Movement Date
                        </label>
                        <p
                            className="flex-center-row bold"
                            style={{ margin: 0 }}
                        >
                            {consignment?.movementDate
                                ? new Date(consignment?.movementDate as any).toMLADateString()
                                : ''}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );

    useEffect(() => {
        if (!movementActions) return;

        if (!consignment || !consignment.number) return;

        const mActions = movementActions && movementActions[consignment.number];
        if (!mActions) return;
        setActions(mActions);
        setShowDevicesMovementToFrom(
            Boolean(mActions?.canPerformMovement || mActions?.canPreviewMovement || mActions?.isReceiver)
        );
        setShowDevicesList(
            validatedDevices.value.size > 0 &&
                (Boolean(mActions?.canAddOrEditDevicesForProducer) ||
                    Boolean(mActions?.canViewMovement) ||
                    Boolean(mActions?.canPerformMovement || mActions?.canPreviewMovement))
        );
        setCanValidateDevices(
            Boolean(mActions?.canAddOrEditDevicesForProducer) || Boolean(mActions?.canPerformMovement)
        );
    }, [consignment, movementActions, validatedDevices.value.size]);

    const handleClick = async () => {
        if (deviceIds.size > 0) {
            if (canValidateDevices) {
                setLoading(true);
                await validateAndAddDevices(consignment?.number as string, ValidationApiAction.ADD);
                setLoading(false);
            } else if (actions?.canPerformMovement) {
                // ToDo call movement api
            }
        }
    };

    const handleRevalidate = async () => {
        const consignmentNumber = consignment?.number as string;

        setLoading(true);

        await revalidate(consignmentNumber);

        setLoading(false);
    };

    useEffect(() => {
        if (errorMessage.startsWith('validation failed')) {
            validationFailedRef.current?.show();
        }
    }, [errorMessage]);

    const isNextDisable = () => {
        if (
            deviceIds.size > 0 &&
            errorMessage.length === 0 &&
            ((showDevicesList && actions?.canPerformMovement) || (!showDevicesList && canValidateDevices))
        ) {
            return false;
        }
        return true;
    };

    const isButtonDisabled = () => {
        if (
            actions?.canPreviewMovement ||
            actions?.canViewMovement ||
            (actions?.isReceiver && !actions?.isAccountAuthorizedForMovement)
        ) {
            return true;
        }
        return false;
    };
    return (
        <>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';

                    .spacer {
                        padding: grid(4) 0;
                    }
                    .upload-container {
                        width: 70%;
                        min-width: 300px;
                    }

                    .added-device-number {
                        color: $unselected-tab-text;
                    }

                    .failed-icon {
                        :global(svg) {
                            width: $icon-lg;
                            height: $icon-lg;
                            color: $color-error;
                        }
                    }

                    .gap {
                        gap: 10px;
                        font-size: 16px;
                    }

                    .modal-header {
                        color: $color-secondary;
                    }
                `}
            </style>
            <div className="spacer" />
            {actions?.isAccountAuthorizedForMovement && actions?.canPreviewMovement && (
                <ValidationCard
                    issueCount={2}
                    title={`NLIS transfer available on ${new Date(consignment?.movementDate as any).toMLADateString()}`}
                    icon={
                        <HoverableIcon
                            Icon={InfoIcon}
                            defaultColor="#004976"
                            hoverColor="#004976"
                        />
                    }
                    description={`Please note that you cannot transfer livestock to your PIC in the NLIS yet. You will be able to do this on the movement date. We recommend waiting until you've received your animals to ensure everything is correct.`}
                />
            )}
            {actions?.isReceiver && !actions?.isAccountAuthorizedForMovement && (
                <ValidationCard
                    status={DeviceValidationStatus.WARNING}
                    issueCount={2}
                    title={`NLIS transfer available on ${new Date(consignment?.movementDate as any).toMLADateString()}`}
                    icon={
                        <HoverableIcon
                            Icon={WarningIcon}
                            defaultColor="#FDB714"
                            hoverColor="#BA8827"
                        />
                    }
                    description={
                        <p>
                            You are not authorized to complete the NLIS movement, as it will be carried out by
                            Producers, Feedlots and Processors. To proceed with viewing the NLIS movement, you must have
                            an NLIS account. Please log in to your NLIS account or create one if you do not have one. Go
                            to{' '}
                            <a
                                href="https://www.nlis.com.au/Account/Create"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {' '}
                                NLIS account creation page
                            </a>
                        </p>
                    }
                />
            )}
            <DeviceMovementInfoHeader />
            {showDevicesMovementToFrom ? (
                <DevicesMovementToFromSection
                    consignment={consignment}
                    validatedDevices={validatedDevices}
                />
            ) : (
                <LivestockDetails />
            )}
            {showDevicesList ? (
                <DeviceList
                    consignment={consignment}
                    onRevalidate={handleRevalidate}
                />
            ) : (
                <AddOrUploadNlisDevices
                    activeTab={activeTab}
                    onTabSwitch={onTabSwitch}
                />
            )}
            {showDevicesList ? (
                !actions?.isReceiver && (actions?.canAddOrEditDevicesForProducer || actions?.canViewMovement) ? (
                    <div className="flex-start-row flex-between">
                        <Button
                            buttonType={isButtonDisabled() ? 'disable-lite' : 'reset'}
                            disabled={isButtonDisabled()}
                        >
                            Remove all devices
                        </Button>
                        <div className="flex-end-row">
                            <Button
                                buttonType={isButtonDisabled() ? 'disable-lite' : 'secondary'}
                                disabled={isButtonDisabled()}
                                onClick={() => replaceAllRef.current?.show()}
                            >
                                Replace devices
                            </Button>
                            <Button
                                onClick={() => history.push(`/consignments/summary/${consignment?.number}`)}
                                disabled={loading}
                                buttonType={'primary'}
                                buttonSize="normal"
                            >
                                Back to Summary Page
                            </Button>
                        </div>
                    </div>
                ) : actions?.isReceiver || actions?.canPreviewMovement || actions?.canPerformMovement ? (
                    <div className="flex-start-row flex-between">
                        <Button
                            buttonType="secondary"
                            onClick={() => history.push(`/consignments/summary/${consignment?.number}`)}
                        >
                            Back to Summary Page
                        </Button>
                        <div className="flex-end-row">
                            <Button
                                buttonType={isButtonDisabled() ? 'disable-lite' : 'secondary'}
                                disabled={isButtonDisabled()}
                                onClick={() => replaceAllRef.current?.show()}
                            >
                                Replace devices
                            </Button>
                            <Button
                                // onClick={() => history.push(`/consignments/summary/${consignment?.number}`)}
                                disabled={loading || isButtonDisabled()}
                                buttonType={isButtonDisabled() ? 'disable' : 'primary'}
                                buttonSize="normal"
                            >
                                Move onto your PIC
                            </Button>
                        </div>
                    </div>
                ) : null
            ) : (
                <div className="flex-start-row flex-between">
                    <Button
                        buttonType="secondary"
                        onClick={() => {
                            clearFiles();
                            history.push(`/consignments/summary/${consignment?.number}`);
                        }}
                    >
                        Back to Summary Page
                    </Button>
                    <Button
                        onClick={handleClick}
                        disabled={loading || isNextDisable()}
                        buttonType={isNextDisable() ? 'disable' : 'primary'}
                        buttonSize="medium"
                    >
                        Next step
                    </Button>
                </div>
            )}
            {actions?.canPreviewMovement && (
                <div
                    className="m-t-16 flex-end-row flex-end"
                    style={{ width: '100%' }}
                >
                    <h5>
                        NLIS movement available on <b>{new Date(consignment?.movementDate as any).toMLADateString()}</b>
                    </h5>
                </div>
            )}
            {actions?.isReceiver && actions?.canPerformMovement && (
                <div
                    className="m-t-16 flex-end-row flex-end"
                    style={{ width: '100%' }}
                >
                    <h5>
                        You can move onto your PIC the provided list, or if you disagree, upload your own tag list by
                        clicking 'Replace devices'.
                    </h5>
                </div>
            )}

            {actions?.canViewMovement && (
                <div
                    className="m-t-16 flex-end-row flex-end"
                    style={{ width: '100%' }}
                >
                    <h5>
                        Edits are no longer available as the movement date of{' '}
                        <b>{new Date(consignment?.movementDate as any).toMLADateString()}</b> has passed.
                    </h5>
                </div>
            )}

            <Modal
                id="loader"
                show={loading}
                loader={loading}
            />
            <ConfirmModal
                actions={[
                    {
                        style: 'secondary',
                        text: 'Back to summary page',
                        buttonSize: 'full-width',
                        action: async () => {
                            validationFailedRef.current?.hide();
                            clearFiles();
                            history.push(`/consignments/summary/${consignment?.number}`);
                        },
                    },
                    {
                        style: 'primary',
                        text: 'Stay on this page',
                        buttonSize: 'full-width',
                        action: async () => {
                            clearFiles();
                            validationFailedRef.current?.hide();
                        },
                    },
                ]}
                ref={validationFailedRef}
                modalId={`confirm-modal`}
            >
                <div className="flex-center-column modal-header p-v-16">
                    <div
                        className="flex-center-row failed-icon gap"
                        style={{ gap: 10, fontSize: 16 }}
                    >
                        <DeviceTransferFailedIcon />
                    </div>
                    <h1>Validation Failed</h1>
                </div>
                <p> Due to a NLIS system error, the validation of attached devices could not be completed. </p>
                <p>
                    Please try resubmitting later. If the issue persists,{' '}
                    <a
                        href="https://www.integritysystems.com.au/help/contact-us/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        contact support
                    </a>{' '}
                    for assistance.
                </p>
            </ConfirmModal>
            <ConfirmModal
                actions={[
                    {
                        style: 'secondary',
                        text: 'Stay on this page',
                        buttonSize: 'full-width',
                        action: async () => {
                            replaceAllRef.current?.hide();
                        },
                    },
                    {
                        style: 'delete',
                        text: 'Replace devices',
                        buttonSize: 'full-width',
                        action: async () => {
                            // clearFiles();
                            clearValidatedDevices();
                            replaceAllRef.current?.hide();
                        },
                    },
                ]}
                ref={replaceAllRef}
                modalId={`confirm-modal`}
            >
                <div className="flex-center-row p-v-16 gap">
                    <div
                        className="flex-center-row failed-icon "
                        style={{ gap: 10, fontSize: 16 }}
                    >
                        <WarningIcon />
                    </div>
                    <b>
                        <h2>Are you sure you want to replace device?</h2>
                    </b>
                </div>
                <p className="m-t-12">
                    By selecting <b>"Replace devices"</b>, all provided tags will be removed, and you'll have the option
                    to add a new list of tags.
                </p>
                <p className="m-t-12">
                    To make minor edits, choose <b>"Stay on this page"</b> and use the <b>"Options"</b> or selector
                    buttons in the table.
                </p>
                <p className="m-t-12">Are you sure you want to proceed with this action?</p>
            </ConfirmModal>
        </>
    );
};

const DevicesMovement: React.FC = () => {
    const { id } = useParams<any>();
    const [{ user }] = useUserState();
    const history = useHistory();
    const envdAccountId = user?.accountDetails?.id;
    const { setValidatedDevices } = useDevicesContext();
    const { clearFiles, clearValidatedDevices } = useDeviceManager();
    const { movementActions } = useMovementContext();
    const { checkMovementActions } = useMovement();
    const { data: consignmentData } = useQuery<any>(
        ConsignmentEditQuery,
        { id, envdAccountId },
        { fetchPolicy: 'network-only', fetchKey: id }
    );
    const shouldFetchDevices = Number(consignmentData?.consignment?.numOfAddedDevices) > 0;
    const { data: devicesData } = useQuery<DeviceGqlQuery>(
        ValidatedDeviceListQuery,
        { consignmentNumber: id, envdAccountId },
        { fetchPolicy: 'network-only', fetchKey: id, skip: !shouldFetchDevices }
    );

    useEffect(() => {
        // clear all device related data before laoding the page so new data can be loaded
        clearFiles();
        clearValidatedDevices();
        if (devicesData) {
            setValidatedDevices({
                value: new Set<ValidatedDevice>(
                    devicesData?.devices?.value?.map((device) => ({
                        nLISID: device?.nLISID ?? '',
                        rFID: device?.rFID ?? '',
                        registeredTo: device?.registeredTo ?? '',
                        species: device?.species ?? '',
                        status: device?.status ?? '',
                        deceased: device?.deceased ?? false,
                    }))
                ),
                totalDevices: Number(devicesData?.devices?.totalDevices) ?? 0,
            });
        }
    }, [clearFiles, clearValidatedDevices, devicesData, setValidatedDevices]);

    useEffect(() => {
        if (consignmentData) {
            const { consignment } = consignmentData;
            const actions = movementActions && movementActions[consignment?.number];
            if (consignment && !actions) {
                checkMovementActions(consignment, user!);
            }
        }
    }, [consignmentData, movementActions, checkMovementActions, user, devicesData, setValidatedDevices]);

    // Handle the rendering logic
    if (!consignmentData) {
        // Show loader until consignmentData is available
        return (
            <Loader
                error={''}
                isLoading
                pastDelay={false}
                timedOut={false}
                retry={() => null}
            />
        );
    }

    const { consignment } = consignmentData;

    if (containsDeprecatedForms(consignment)) {
        // Redirect to consignments if forms are deprecated
        history.replace('/consignments');
        return null;
    }

    let cloneConsignment = _.clone(consignment);

    if (consignment?.destination?.pic === UNKNOWN_PIC) {
        cloneConsignment = cleanConsignmentPIC(cloneConsignment, 'destination', '');
    }

    if (consignment?.numOfAddedDevices > 0) {
        // Show loader until devicesData is available
        if (!devicesData) {
            return (
                <Loader
                    error={''}
                    isLoading
                    pastDelay={false}
                    timedOut={false}
                    retry={() => null}
                />
            );
        }
    }

    // Render DeviceMovementInfo when all conditions are met
    return <DeviceMovementInfo consignment={cloneConsignment} />;
};

export default DevicesMovement;

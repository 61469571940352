import useKeyPress, { KeyCode, useLocalKeyPress } from '@common/effects/useKeyPress';
import DropdownOptions, { DropdownOption } from '@components/Form/DropdownOptions';
import MLALogger from '@utils/logger';
import classnames from 'classnames';
import React, { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';

import Button, { ButtonSize, ButtonType } from '../Button';
import RelativePortal from '../RelativePortal';

export interface ContextMenuOption extends DropdownOption {
    onClick(): any;
}

interface Props {
    buttonType?: ButtonType;
    buttonSize?: ButtonSize;
    buttonText?: string;
    buttonDisabled?: boolean;
    icon?: any;
    prefixIcon?: React.ReactNode;
    options: ContextMenuOption[];
    tabIndex?: number;
    fixedWidth?: string;
    onShowChange?: (isShown: boolean) => void
}
export interface ContextMenuRef {
    hide(): void;
}

const calculateActiveOptionIndex = (value: number, options: DropdownOption[], currentOptionIndex?: number): number => {
    let val = (currentOptionIndex ? currentOptionIndex : 0) + value;
    if (val < 0) {
        val = options.length - 1;
    }
    const optionIndex = val % options.length;
    MLALogger.Log(['ContextMenu'], { value, currentOptionIndex, optionIndex });
    return optionIndex;
};

const ContextMenu = forwardRef(
    ({ options, buttonType = 'tertiary', buttonSize = 'small', buttonText, buttonDisabled = false, icon, fixedWidth = '320px', tabIndex = 0, onShowChange, prefixIcon }: Props, ref: React.Ref<ContextMenuRef | undefined | null>) => {
        const node = useRef<HTMLDivElement>(null);
        const [show, setShow] = useState(false);
        const [activeOptionIndex, setActiveOptionIndex] = useState<number | undefined>();

        const downKeyPress = useLocalKeyPress(KeyCode.downArrow, node.current);
        const upKeyPress = useLocalKeyPress(KeyCode.upArrow, node.current);
        const enterKeyPress = useLocalKeyPress(KeyCode.enter, node.current);
        const escKeyPress = useKeyPress(KeyCode.escape);

        useImperativeHandle(ref, () => ({
            hide: () => {
                setShow(false);
            },
        }));
        const optionSelected = useCallback(
            (idx: any) => {
                if (idx !== null && idx !== undefined) {
                    const option = options[idx];
                    // Prevent multiple executions of the same action
                    setShow(false);
                    // Add setTimeout to ensure state is updated before executing action
                    setTimeout(() => {
                        option.onClick();
                    }, 0);
                }
            },
            [options]
        );

        useLayoutEffect(() => {
            if (downKeyPress) {
                setActiveOptionIndex((internalAOI) => calculateActiveOptionIndex(1, options, internalAOI));
            }
        }, [downKeyPress, options]);

        useLayoutEffect(() => {
            if (upKeyPress) {
                setActiveOptionIndex((internalAOI) => calculateActiveOptionIndex(-1, options, internalAOI));
            }
        }, [upKeyPress, options]);

        useLayoutEffect(() => {
            if (enterKeyPress) {
                optionSelected(activeOptionIndex);
            }
        }, [enterKeyPress, activeOptionIndex, optionSelected]);

        useLayoutEffect(() => {
            if (escKeyPress) {
                setShow(false);
            }
        }, [escKeyPress]);

        if (options.length === 0) {
            return null;
        }

        return (
            <>
                <style jsx>{`
                    @import 'vars';
                    @import 'utils';

                    .form-element {
                        padding: grid(3);
                        user-select: none;

                        &.with-success {
                            border-color: $color-success;
                        }

                        &.with-error {
                            border-color: $color-error;
                        }

                        &.active {
                            border-color: $color-primary;
                            background-color: $color-fade;
                        }
                    }
                `}</style>

                <div ref={node}>
                    <Button
                        buttonType={buttonDisabled ? 'tertiary-disable' : buttonType}
                        buttonSize={buttonSize}
                        disabled={buttonDisabled}
                        className={classnames({
                            active: show
                        })}
                        action={() => {
                            setShow(!show);
                            onShowChange?.(!show);
                        }}
                    >
                        {prefixIcon}
                        {buttonText && <span className="m-r-8">{buttonText}</span>}
                        {icon}
                    </Button>
                    <RelativePortal
                        id={'context-menu-portal'}
                        fitToParentWidth={false}
                        show={show}
                        // onParentClick={optionSelected}
                        onOutClick={() => {
                            // Callback variant of setting state will allow us to get the most up to date version of the state
                            setShow(false);
                            onShowChange?.(false);
                        }}
                        parentElementRef={node}
                    >
                        <DropdownOptions options={options} selectedIndex={activeOptionIndex} fixedWidth={fixedWidth} onOptionClick={(item) => {
                            setShow(false);
                            item && item.onClick && item?.onClick();
                        }} />
                    </RelativePortal>
                </div>
            </>
        );
    }
);
export default ContextMenu;

import { ClearIcon, FilterIcon } from '@assets/icons';
import Button, { ButtonSize, ButtonType } from '@components/Button';
import ClickableIcon from '@components/ClickableIcon';
import RelativePortal from '@components/RelativePortal';
import classnames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';

import FilterMenuOptions, { IFilterGroupMenuGroup } from './FilterMenuOptions';
import Popover from './Popover';

export interface FilterMenuProps {
    title: string;
    groups: IFilterGroupMenuGroup[];
    onUpdate: (groups: IFilterGroupMenuGroup[]) => void;

    buttonType?: ButtonType;
    buttonSize?: ButtonSize;
    buttonText?: string;
}

const FilterMenu: React.FC<FilterMenuProps> = ({ title, groups, onUpdate, buttonType = 'tertiary', buttonSize = 'small', buttonText = 'Filter' }) => {
    const node = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState(false);

    const closeAction = useCallback(() => {
        setShow(false);
    }, []);

    return (
        <div className="FilterMenu" ref={node}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                :global(.Popover) {
                    // Full screen when mobile
                    @media (max-width: $sm-max) {
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        position: fixed !important;
                        border: none !important;
                        margin: 0 !important;
                        border-radius: 0 !important;
                        padding: grid(8) !important;

                        :global(.Popover--Content) {
                            height: 100% !important;
                            width: 100% !important;
                            max-height: 100% !important;
                        }
                    }
                }

                .form-element {
                    padding: grid(3);
                    user-select: none;

                    &.with-success {
                        border-color: $color-success;
                    }

                    &.with-error {
                        border-color: $color-error;
                    }

                    &.active {
                        border-color: $color-primary;
                        background-color: $color-fade;
                    }
                }

                .FilterMenuPopoverHeader {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: grid(4);

                    position: sticky;
                    top: 0;
                    background: $color-white;
                    z-index: 99;

                    @media (prefers-color-scheme: dark) {
                        background: $color-black;
                        color: $color-white;
                    }
                }

                .FilterMenuPopoverFooter {
                    display: none;

                    // Visible on mobile
                    @media (max-width: $sm-max) {
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: block;
                    }
                }
            `}</style>
            <Button
                buttonType={buttonType}
                buttonSize={buttonSize}
                className={classnames({
                    active: show,
                })}
                action={() => setShow(!show)}
            >
                <FilterIcon />
                {buttonText && <span className="m-h-8">{buttonText}</span>}
            </Button>
            <RelativePortal
                id={'filter-menu-portal'}
                fitToParentWidth={false}
                show={show}
                onParentClick={undefined}
                onOutClick={() => {
                    // Callback variant of setting state will allow us to get the most up to date version of the state
                    setShow((s) => {
                        return s ? !s : s;
                    });
                }}
                parentElementRef={node}
            >
                <Popover>
                    <div className="FilterMenuPopoverHeader">
                        <h3>{title}</h3>
                        <ClickableIcon onClick={closeAction} alt="Close" icon={<ClearIcon />} />
                    </div>
                    <FilterMenuOptions groups={groups} onUpdate={onUpdate} />
                    <div className="FilterMenuPopoverFooter">
                        <Button buttonType="primary" fullWidth onClick={closeAction}>
                            Done
                        </Button>
                    </div>
                </Popover>
            </RelativePortal>
        </div>
    );
};

export default FilterMenu;

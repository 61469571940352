import React from "react";

const DeviceTransferPendingIcon = ({color = "#BA8827", ...props }) => {
    return (
        <svg
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m13.276 6.719-6-6a1.33 1.33 0 0 0-.94-.387H1.669C.936.332.336.932.336 1.665v4.667c0 .353.14.693.393.94l.274.267c.6-.36 1.293-.54 2-.54 2.206 0 4 1.793 4 4 0 .706-.187 1.393-.547 2l.267.266c.246.254.593.4.946.4.354 0 .694-.14.94-.393l4.667-4.667a1.3 1.3 0 0 0 .393-.94c0-.353-.14-.693-.393-.946M2.669 3.665c-.553 0-1-.446-1-1 0-.553.447-1 1-1 .554 0 1 .447 1 1 0 .554-.446 1-1 1m.334 6.667H.336v1.333h2.667V13l2-2-2-2z"
                fill={color}
            />
        </svg>
    );
};

export default DeviceTransferPendingIcon;

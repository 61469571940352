import Button from '@common/components/Button';
import Modal from '@common/components/Modal';
import useKeyPress, { KeyCode } from '@common/effects/useKeyPress';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

interface Props {
    modalId: string;
    title?: React.ReactNode;
    actions: {
        text: string;
        style: 'primary' | 'delete' | 'secondary';
        buttonSize?: 'full-width';
        action: () => void;
    }[];
    children?: React.ReactNode;
    cancelText?: string;
    onCancel?: () => void;
    modalWarning?: string;
}

export interface ConfirmModalRef {
    show(): void;
    hide(): void;
}

const ConfirmModal = forwardRef(
    (
        { modalId, title, actions, children, onCancel, modalWarning, cancelText = 'Cancel' }: Props,
        ref: React.Ref<ConfirmModalRef | undefined | null>
    ) => {
        const [show, setShow] = useState(false);
        const [isLoading, setIsLoading] = useState<number | null>(null);
        const escKeyPress = useKeyPress(KeyCode.escape);

        useEffect(() => {
            if (show) {
                setShow(false);
                if (onCancel) {
                    onCancel();
                }
            }
            // TODO: Clean this component up
            // eslint-disable-next-line
        }, [escKeyPress]);

        useImperativeHandle(ref, () => ({
            show: () => {
                setShow(true);
                setIsLoading(null);
            },
            hide: () => {
                setShow(false);
                setIsLoading(null);
            },
        }));

        if (!show) {
            return null;
        }

        return (
            <Modal
                disableOutClick={true}
                id={modalId}
                show={show}
                modalWarning={modalWarning}
                modalHeader={title}
                modalFooter={
                    <>
                        {onCancel && (
                            <Button
                                buttonType={'secondary'}
                                buttonSize="small"
                                disabled={isLoading !== null}
                                onClick={() => {
                                    setShow(false);
                                    if (onCancel) {
                                        onCancel();
                                    }
                                }}
                            >
                                {cancelText}
                            </Button>
                        )}
                        {actions?.map((item, i) => (
                            <Button
                                key={i}
                                buttonType={item.style}
                                buttonSize={item.buttonSize ?? 'small'}
                                type={'submit'}
                                onClick={() => {
                                    setIsLoading(i);
                                    item.action();
                                }}
                                disabled={isLoading !== null}
                                loading={isLoading === i}
                            >
                                {item.text}
                            </Button>
                        ))}
                    </>
                }
                onOutClick={() => {
                    setShow(false);
                    if (onCancel) {
                        onCancel();
                    }
                }}
            >
                {children}
            </Modal>
        );
    }
);

export default ConfirmModal;

import React from 'react';

const DeviceTransferFailedIcon = ({ color = '#C64E3E', ...props }) => {
    return (
        <svg
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m13.276 6.72-6-6a1.33 1.33 0 0 0-.94-.386H1.669A1.333 1.333 0 0 0 .336 1.667v4.667c0 .353.14.693.393.94l.274.267c.6-.36 1.293-.54 2-.54a4 4 0 0 1 4 4c0 .706-.187 1.393-.547 2l.267.266c.246.254.593.4.946.4.354 0 .694-.14.94-.393l4.667-4.667a1.3 1.3 0 0 0 .393-.94c0-.353-.14-.693-.393-.946M2.669 3.668a1 1 0 1 1 0-2 1 1 0 0 1 0 2m1.747 9.694-1.413-1.42-1.414 1.42-.946-.947 1.42-1.413-1.42-1.414.94-.94 1.42 1.414 1.413-1.414.94.94-1.413 1.414 1.413 1.413z"
                fill={color}
            />
        </svg>
    );
};

export default DeviceTransferFailedIcon;

import { ValidatedDevice } from '@common/context/DevicesContext';
import EmptyList from '@components/Listing/EmptyList';
import Loader from '@components/Loader';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import DeviceRow from './DeviceRow';

interface HeaderProps {
    className: string;
}
export const Header: React.FC<HeaderProps> = ({ className }) => {
    return (
        <>
            <style jsx>{`
                .tbl-padding {
                    padding-right: 15px;
                }
            `}</style>
            <div className={`tbl-row row-header ${className}`}>
                <div className="tbl-cell-10 column-heading">Validation</div>
                <div className="tbl-cell-20 column-heading">NLISID</div>
                <div className="tbl-cell-20 column-heading">RFID</div>
                <div className="tbl-cell-15 column-heading">Livestock</div>
                <div className="tbl-cell-15 column-heading">Registered PIC</div>
                <div className="tbl-cell-15 column-heading">Device Status</div>
                <div className="tbl-cell-10 column-heading">Deceased</div>
                <div className="tbl-cell-15 column-heading" />
            </div>
        </>
    );
};

interface DeviceTableProps {
    validatedDevices: ValidatedDevice[];
    consignment: ConsignmentDetailQueryResponse['consignment'];
    isSearchOrFilter: boolean;
}
const DeviceTable: React.FC<DeviceTableProps> = ({ validatedDevices, consignment, isSearchOrFilter }) => {
    const ITEMS_PER_PAGE = 10;
    const [displayDevices, setDisplayDevices] = useState<ValidatedDevice[]>([]);
    const [hasMoreDevices, setHasMoreDevices] = useState(true);
    const [isDropdown, setIsDropdown] = useState(false);
    // Key state to force the InfiniteScroll component to re-mount when data changes.
    const [key, setKey] = useState(0);
    const [openDropdownCount, setOpenDropdownCount] = useState(0);
    useEffect(() => {
        // Reset everything when validatedDevices changes
        const initialItems = validatedDevices.slice(0, ITEMS_PER_PAGE);
        setDisplayDevices(initialItems);
        setHasMoreDevices(validatedDevices.length > ITEMS_PER_PAGE);
        // Force the InfiniteScroll component to re-mount when data changes
        setKey((prev) => prev + 1);
    }, [validatedDevices]);

    const loadMore = (page: number) => {
        const startIndex = (page - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        // Get a subset of devices from startIndex to endIndex
        const newItems = validatedDevices.slice(startIndex, endIndex);
        // Update displayDevices state by appending the new items
        setDisplayDevices((prevItems) => {
            const existingIds = new Set(prevItems.map(item => item.nLISID || item.rFID));
            const uniqueNewItems = newItems.filter(item => !existingIds.has(item.nLISID || item.rFID));
            return [...prevItems, ...uniqueNewItems];
        });

        if (endIndex >= validatedDevices.length) {
            setHasMoreDevices(false);
        }
    };

    const handleDropdownChange = (isOpen: boolean) => {
        if (isOpen) {
            setOpenDropdownCount(prev => prev + 1);
            setIsDropdown(true);
        } else {
            setOpenDropdownCount(prev => prev - 1);
            // Only hide scroll when all dropdowns are closed
            setIsDropdown(prev => openDropdownCount - 1 > 0);
        }
    };

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';
                .tbl {
                    @media (max-width: $md-max) {
                        border: none;
                        background: transparent;
                    }
                } 
                .tbl-padding {
                    padding-right: 15;
                }
            `}</style>

            <div className="tbl tbl-collapse">
                <Header className={validatedDevices.length >= 7 ? 'tbl-padding' : ''} />
                <div
                    style={{
                        width: '100%',
                        height: 600,
                        overflowY: isDropdown ? 'hidden' : 'auto', // Enable vertical scrolling
                    }}
                >
                    <InfiniteScroll
                        threshold={50}
                        key={key}
                        className="tbl-body"
                        data-cy="consignment-records"
                        pageStart={0}
                        loadMore={loadMore}
                        hasMore={hasMoreDevices}
                        loader={
                            <div className="tbl-row" key={0}>
                                <Loader isLoading={true} error={''} retry={() => null} timedOut={false} pastDelay={false} />
                            </div>
                        }
                        useWindow={false}
                    >
                        {displayDevices?.length === 0
                            ? isSearchOrFilter
                                ? (<EmptyList
                                    emptyText={
                                        <>
                                            <h1>No results found</h1>
                                            <br />
                                            <p>We couldn't find the devices you were looking for.</p>
                                            <p>Update your search or filter parameters and try again.</p>
                                        </>
                                    }
                                />
                                )
                                : (<EmptyList
                                    emptyText={
                                        <>
                                            <h1>No results found</h1>
                                            <br />
                                            <p>We couldn't find the devices you were looking for.</p>
                                        </>
                                    }
                                />
                                )
                            : displayDevices?.map((device: ValidatedDevice) => {
                                return <DeviceRow key={device!.nLISID ?? device!.rFID} setIsDropdown={handleDropdownChange} validatedDevice={device!} consignment={consignment} />;
                            })}
                    </InfiniteScroll>
                </div>
            </div>
        </>
    );
};

export default DeviceTable;

import * as React from 'react';

const DeviceTransferReadyIcon = ({color = "#20A273", ...props }) => {
    return (
        <svg
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m13.276 6.719-6-6a1.33 1.33 0 0 0-.94-.387H1.669A1.333 1.333 0 0 0 .336 1.665v4.667c0 .353.14.693.393.94l.274.267c.6-.36 1.293-.54 2-.54a4 4 0 0 1 4 4c0 .706-.187 1.393-.547 2l.267.266c.246.254.593.4.946.4.354 0 .694-.14.94-.393l4.667-4.667a1.3 1.3 0 0 0 .393-.94c0-.353-.14-.693-.393-.946M2.669 3.665a1 1 0 1 1 0-2 1 1 0 0 1 0 2m3 8h-2v2H2.336v-2h-2v-1.333h2v-2h1.333v2h2z"
                fill={color}
            />
        </svg>
    );
};

export default DeviceTransferReadyIcon;

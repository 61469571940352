import React from "react";

const DeviceTransferNotReadyIcon = ({color = "#848A94", ...props }) => {
    return (
        <svg
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m8.596 1.539-.82-.82a1.33 1.33 0 0 0-.94-.387h-.667v1.333h.667l.82.82zm-.473 1.413.946-.953 1.414 1.426-.947.94zm-5.954 3.38.82.82-.946.94-.814-.813a1.4 1.4 0 0 1-.293-.434c-.067-.16-.1-.34-.1-.513v-.667h1.333zm.334 2.233.953-.946 1.413 1.413-.94.947zm3.313 3.3-1.413-1.413.94-.947 1.413 1.414zm4.193-7.026 1.414 1.413.946-.94-1.413-1.413zm1.887 1.886.94-.946.94.94c.127.126.227.28.293.433.067.18.1.34.1.513 0 .354-.14.694-.393.94zm1.407 2.354-.94-.94-1.627 1.626.94.94zm-3.034 1.153.94.94-1.626 1.627-.94-.94zm-1.16 3.04-1.88-1.88-.946.94.946.947c.254.246.587.386.94.386.354 0 .694-.14.94-.393M2.17 4.999H.836v-2h1.333zM.836 2.332h1.333v-.667h.667V.332h-.667c-.353 0-.693.14-.94.393a1.3 1.3 0 0 0-.393.94zm2.667-.667V.332h2v1.333zm.333 2.667c.553 0 1-.447 1-1s-.447-1-1-1-1 .447-1 1 .447 1 1 1"
                fill={color}
            />
        </svg>
    );
};

export default DeviceTransferNotReadyIcon;

import { ChevronForwardIcon, InfoIcon } from '@assets/icons';
import { ValidatedDeviceResponse } from '@common/context/DevicesContext';
import Tooltip from '@components/Form/Tooltip';
import { speciesEnumToName } from '@utils/enum-transformers';
import React from 'react';

import { ConsignmentDetailQueryResponse } from '../__generated__/ConsignmentDetailQuery.graphql';
import { ID_QUESTION_DESCRIPTION_HEADS } from '../ConsignmentDetail';

const MovingToFromSummary: React.FC<{ consignment: ConsignmentDetailQueryResponse['consignment'], isDeviceMovement?: boolean, validatedDevices?: ValidatedDeviceResponse }> = ({ consignment, isDeviceMovement = false, validatedDevices }) => {
    const formatAddressSection = (section: any) => {
        if (section) {
            return ', ' + section;
        }
        return '';
    };

    const getAddress = (address: any) => {
        let formattedAddress = formatAddressSection(address?.line1) + formatAddressSection(address?.town) + formatAddressSection(address?.state) + formatAddressSection(address?.postcode);
        formattedAddress = formattedAddress.trim();
        if (formattedAddress && formattedAddress.charAt(0) === ',') {
            return formattedAddress.substring(1).trim();
        }
    };
    const getTotalLiveStockCount = (): number | null | undefined => {
        return consignment?.answers?.filter((dq: any) => ID_QUESTION_DESCRIPTION_HEADS === dq.questionId).reduce((a, b) => +a + Number(b?.value), 0);
    };

    return (
        <>
            <style jsx>
                {`
                @import 'mixins';
                @import 'vars';
                @import 'layout';

                .to-details {
                    border-left: 1px solid $grey-border;
                    position: relative;
                    width: 100%
                }

                .box {
                    box-sizing: border-box;
                    background-color: $color-white;
                    border: 1px solid $grey-border;
                    border-radius: 4px;
                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }

                .panel-body {
                    gap: 20px;
                    overflow-wrap: anywhere;
                    @media (max-width: $lg-max) {
                        flex-wrap: wrap;
                    }
                }

                .highlight {
                    color: $isc-blue;
                }

                .icon-wrapper {
                    background: $color-fade;
                    border: 2px solid $light-grey;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    border-radius: 40px;
                    aspect-ratio: 1/1;
                }

                .icon-wrapper {
                    position: absolute;
                    left: -20px;
                    top: 40%;
                    border-width: 1px;
                    height: 40px;
                }

                .livestock-details {
                    flex: 1 0 30%;
                }

                .movement-details{
                    width: 100%;
                }

                .from-details{
                    width: 100%
                }
                .icon-info {
                    color: $info-icon;
                }
            `}
            </style>
            <div className="panel-body flex-row p-v-16 p-h-16">
                <div className="movement-details flex-row box">
                    <div className="from-details p-v-32 p-h-32">
                        <h3 className="m-b-8">Moving From</h3>
                        <p className="highlight">{consignment?.origin?.name}</p>
                        <p className="highlight">{consignment?.origin?.pic}</p>
                        <p>{getAddress(consignment?.origin?.address)}</p>
                    </div>
                    <div className="to-details p-v-32 p-h-32">
                        <div className="icon-wrapper">
                            <ChevronForwardIcon />
                        </div>
                        <h3 className="m-b-8">Moving To</h3>
                        <p className="highlight">{consignment?.destination?.name}</p>
                        <p className="highlight">{consignment?.destination?.pic}</p>
                        <p>{getAddress(consignment?.destination?.address)}</p>
                    </div>
                </div>
                <div className="livestock-details p-v-28 p-h-20 box">
                    <h3 className="m-b-8">Livestock Details</h3>
                    <p className="m-b-8">Species: {speciesEnumToName(consignment?.species || null)}</p>
                    {isDeviceMovement && <p className='flex-center-row'>Total NLIS devices
                        <div className="icon-info">
                            <Tooltip
                                placement='bottom'
                                title={`What does "Total NLIS devices" mean?`}
                                text={`"Total NLIS devices" refers to all identifiers used for livestock description in the consignment, including NLIS IDs, RFIDs (ear tags), and rumen devices (cattle only). Ensure consistency between livestock descriptions and your added/upload NLIS devices wherever possible.`}
                                children={<InfoIcon style={{ width: 18 }} />}
                            />
                        </div>
                        :{" "}
                        {validatedDevices?.totalDevices ?? consignment?.numOfAddedDevices}
                    </p>}
                    <p>Total number of heads: {getTotalLiveStockCount()}</p>
                </div>
            </div>
        </>
    );
};

export default MovingToFromSummary;

import Tooltip from '@components/Form/Tooltip';
import { DeviceValidationStatus } from '@utils/enums';
import React from 'react';

interface ValidationCardProps {
    status?: DeviceValidationStatus;
    issueCount?: number;
    title?: string;
    description?: React.ReactNode;
    icon?: JSX.Element
    tooltip?: string;
}

/**
 * Card component for Device Validation information.
 * @param props
 */
const ValidationCard: React.FC<ValidationCardProps> = ({ status, title, description, icon, tooltip }) => {
    return (
        <>
            <div className={`flex-row box flex-between ${status ? status.toLowerCase() : 'normal'} m-b-20`}>
                <div className="flex-row flex-between transfer-content p-v-12">
                    <div className="icon-container p-v-4">
                        {tooltip
                            ? <Tooltip
                                placement="right"
                                text={tooltip}
                            >
                                {icon}
                            </Tooltip>
                            : icon
                        }
                    </div>
                    <div className="content-container">
                        <h4 className="midbold">{title}</h4>
                        <p className="p-t-8 p-r-8 m-b-0">{description}</p>
                    </div>
                </div>
            </div>

            <style jsx>{`
                @import 'mixins';
                @import 'vars';
                @import 'layout';

                .box {
                    box-sizing: border-box;
                    background-color: $color-white;
                    border-radius: 4px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }

                .icon-container {
                    flex: 0 0 60px;
                    display: flex;
                    justify-content: center;
                }

                .content-container {
                    flex-grow: 1;
                    text-align: left;
                }

                .transfer-content {
                    width: 100%;
                }

                .validated {
                    border: 1px solid $color-success;
                }

                .warning {
                    border: 1px solid $color-warn;
                }

                .error {
                    border: 1px solid $color-error;
                }

                .normal {
                    border: 1px solid $isc-blue;
                }
            `}</style>
        </>
    );
};

export default ValidationCard;
